import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WishListPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Wishlist" />
      <h1>Wishlist</h1>
      <p>Some things I'd like. Unlinked means I'm still researching.</p>
      <h2>Music gear</h2>
      <ul>
        <li>
          <a target="_blank"
             href="https://roli.com/products/blocks/lumi-keys-studio-edition">
            Roli's LUMI Keys Studio Edition</a>
        </li>
        <li>
          <a target="_blank"
             href="https://www.ableton.com/en/push/">
            <strike>Ableton Push 2</strike></a>
        </li>
        <li>
          <a target="_blank"
             href="https://www.korg.com/us/products/synthesizers/minilogue_xd/">
            <strike>Korg Minilogue XD</strike></a>
        </li>
        <li>Arturia KeyStep</li>
      </ul>
      <h2>Cycling gear</h2>
      <ul>
        <li>Bike rack for car</li>
        <li>Bib shorts</li>
        <li>Clipless (clip-in) pedals</li>
        <li>Shoes for clipless pedals</li>
      </ul>
    </Layout>
  )
}

export default WishListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
